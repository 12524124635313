import React, { useState } from 'react';

function Signup({ setUsername }) {
  const [input, setInput] = useState('');

  const handleSignup = () => {
    if (input.trim()) {
      // Check if user data already exists
      const existingUserData = JSON.parse(localStorage.getItem(input.trim())) || null;
      if (!existingUserData) {
        // Initialize user data if not found
        localStorage.setItem(input.trim(), JSON.stringify({ breaks: 0, avgBalls: 0 }));
      }
      setUsername(input.trim());
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>Welcome to Pool Tracker</h1>
      <input
        type="text"
        placeholder="Enter your username"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <button onClick={handleSignup}>Sign Up</button>
    </div>
  );
}

export default Signup;
