import React, { useState, useEffect } from 'react';

function Tracker({ username }) {
  const [tableSize, setTableSize] = useState('7ft');
  const [gameType, setGameType] = useState('8 Ball');
  const [ballsMade, setBallsMade] = useState('');
  const [stats, setStats] = useState({});

  // Load user stats from Local Storage when the component mounts
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem(username));
    if (userData) {
      setStats(userData);
    } else {
      // Initialize stats structure for new users
      const initialStats = {
        "7ft": { "8 Ball": { breaks: 0, totalBalls: 0, dryBreaks: 0 }, "9 Ball": { breaks: 0, totalBalls: 0, dryBreaks: 0 } },
        "8ft": { "8 Ball": { breaks: 0, totalBalls: 0, dryBreaks: 0 }, "9 Ball": { breaks: 0, totalBalls: 0, dryBreaks: 0 } },
        "9ft": { "8 Ball": { breaks: 0, totalBalls: 0, dryBreaks: 0 }, "9 Ball": { breaks: 0, totalBalls: 0, dryBreaks: 0 } }
      };
      setStats(initialStats);
      localStorage.setItem(username, JSON.stringify(initialStats));
    }
  }, [username]);

  const handleSubmit = () => {
    if (ballsMade === '') return;

    const newStats = { ...stats };

    // Ensure tableSize and gameType stats exist
    if (!newStats[tableSize]) {
      newStats[tableSize] = {};
    }
    if (!newStats[tableSize][gameType]) {
      newStats[tableSize][gameType] = { breaks: 0, totalBalls: 0, dryBreaks: 0 };
    }

    // Update stats for the selected table size and game type
    const currentStats = newStats[tableSize][gameType];
    currentStats.breaks += 1;
    currentStats.totalBalls += parseInt(ballsMade, 10);
    if (parseInt(ballsMade, 10) === 0) {
      currentStats.dryBreaks += 1;
    }

    // Save updated stats
    newStats[tableSize][gameType] = currentStats;
    setStats(newStats);
    localStorage.setItem(username, JSON.stringify(newStats));

    // Reset ballsMade input
    setBallsMade('');
  };

  const calculateAverage = (totalBalls, breaks) =>
    breaks === 0 ? 0 : (totalBalls / breaks).toFixed(2);

  const calculateDryBreakPercentage = (dryBreaks, breaks) =>
    breaks === 0 ? '0%' : ((dryBreaks / breaks) * 100).toFixed(2) + '%';

  return (
    <div style={{ textAlign: 'center', marginTop: '10%' }}>
      <h1>Hello, {username}</h1>
      <h2>Pool Tracker</h2>

      <label>
        Table Size:
        <select value={tableSize} onChange={(e) => setTableSize(e.target.value)}>
          <option value="7ft">7 ft</option>
          <option value="8ft">8 ft</option>
          <option value="9ft">9 ft</option>
        </select>
      </label>

      <label>
        Game Type:
        <select value={gameType} onChange={(e) => setGameType(e.target.value)}>
          <option value="8 Ball">8 Ball</option>
          <option value="9 Ball">9 Ball</option>
        </select>
      </label>

      <label>
        Balls Made on Break:
        <input
          type="number"
          value={ballsMade}
          onChange={(e) => setBallsMade(e.target.value)}
        />
      </label>
      <button onClick={handleSubmit}>Submit</button>

      <h3>Statistics</h3>
      {Object.keys(stats).map((table) => (
        <div key={table}>
          <h4>{table} Table</h4>
          {Object.keys(stats[table]).map((game) => (
            <div key={game}>
              <p>
                {game} - Average Balls Per Break:{' '}
                {calculateAverage(stats[table][game].totalBalls, stats[table][game].breaks)}
              </p>
              <p>
                {game} - Dry Break Percentage:{' '}
                {calculateDryBreakPercentage(stats[table][game].dryBreaks, stats[table][game].breaks)}
              </p>
            </div>
          ))}
        </div>
      ))}

      <button onClick={() => window.location.reload()}>Logout</button>
    </div>
  );
}

export default Tracker;
