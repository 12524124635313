import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Signup from './components/Signup';
import Tracker from './components/Tracker';

function App() {
  const [username, setUsername] = useState(null);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={!username ? <Signup setUsername={setUsername} /> : <Navigate to="/tracker" />}
        />
        <Route
          path="/tracker"
          element={username ? <Tracker username={username} /> : <Navigate to="/" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
